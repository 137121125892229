import { useContext } from 'react';

import {
  GlobalContext,
  GlobalContextValue,
} from '@hultafors/solidgear/context';

export function useGlobal(): GlobalContextValue {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobal must be used within GlobalContext!');
  }
  return context;
}
