export const breakpointsRaw = {
  bigMobile: 480,
  contentWidthDesktop: 710,
  desktop: 1025,

  desktopLarge: 1440,

  desktopLarger: 1600,

  // Wibeladders, should be refactored away
  desktopMedium: 1180,

  desktopMediumLarge: 1366,
  // TODO set this to 1024 if safe?
  desktopSmall: 860,
  desktopXL: 2000,
  largeMobile: 600,
  maxGridWidth: 1050,
  maxPageWidth: 1440,
  mediumMobile: 376,
  mediumMobileMax: 375,
  mobile: 320,
  mobileMax: 767,
  tablet: 768,
} as const;

export const breakpoints = {
  bigMobile: `${breakpointsRaw.bigMobile}px`,
  contentWidthDesktop: `${breakpointsRaw.contentWidthDesktop}px`,
  desktop: `${breakpointsRaw.desktop}px`,
  desktopLarge: `${breakpointsRaw.desktopLarge}px`,
  desktopLarger: `${breakpointsRaw.desktopLarger}px`,
  desktopMedium: `${breakpointsRaw.desktopMedium}px`,
  desktopMediumLarge: `${breakpointsRaw.desktopMediumLarge}px`,
  desktopSmall: `${breakpointsRaw.desktopSmall}px`,
  desktopXL: `${breakpointsRaw.desktopXL}px`,
  largeMobile: `${breakpointsRaw.largeMobile}px`,
  maxGridWidth: `${breakpointsRaw.maxGridWidth}px`,
  maxPageWidth: `${breakpointsRaw.maxPageWidth}px`,
  mediumMobile: `${breakpointsRaw.mediumMobile}px`,
  mediumMobileMax: `${breakpointsRaw.mediumMobileMax}px`,
  mobile: `${breakpointsRaw.mobile}px`,
  mobileMax: `${breakpointsRaw.mobileMax}px`,
  tablet: `${breakpointsRaw.tablet}px`,
} as const;

export type Breakpoint = keyof typeof breakpoints;
