export const PAGE_SIZE_S = 3;
export const PAGE_SIZE_L = 6;
export const RADIUS_ALTERNATIVES = [25, 50, 100];
export const DEFAULT_RADIUS = 50;
export const TABS = {
  onlineStores: 1,
  search: 0,
} as const;

// ids
export const SAFETY_FILTER_ID = 843;
export const COMBINE_SECTION_ID = 55;
export const ALL_PRODUCTS_NODE_ID = 793;

// keys
export const CART_KEY = 'sg_cart';
export const FAVORITES_KEY = 'sg_favorites';

export const PAGE_MODEL_KEYS = {
  CATEGORY: 'CategoryPageRecord',
} as const;

// routes
const LANDING_VIEW = '/';
const PRODUCTS = 'products';
const CONTENT = '';
const FIND_RETAILERS = 'find-retailers';
const DEPRECATED_RETAILERS = 'find_retailers';
const SEARCH = 'search';
const CONTACT = 'contact';
const GUIDES = 'guides';
const CAMPAIGN = 'campaigns';
const CATEGORY = 'products';
const INFORMATION = 'info';

export const routes = {
  CAMPAIGN,
  CATEGORY,
  CONTACT,
  CONTENT,
  DEPRECATED_RETAILERS,
  FIND_RETAILERS,
  GUIDES,
  INFORMATION,
  LANDING_VIEW,
  PRODUCTS,
  SEARCH,
} as const;
