/**
 * Merges two sets of URLSearchParams.
 * @param input1 First data set to merge
 * @param input2 Second data set to merge
 * @returns Merges URLSearchParams
 */
export const mergeUrlSearchParams = (
  input1: string | URLSearchParams | Record<string, string>,
  input2: string | URLSearchParams | Record<string, string>,
): URLSearchParams => {
  const params = new URLSearchParams(input1);
  new URLSearchParams(input2).forEach((value, key) => {
    params.set(key, value);
  });
  return params;
};
