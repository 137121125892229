export const spacing = {
  // 96px
  block: '2rem',

  fullNavHeightInclLangSelector: '80px',

  large: '96px',

  medium: '48px',

  // These are not in the styleguide and should be replaced
  mega: '17.5rem',

  menuHeight: '64px',

  mobileTop: '72px',

  // 32px
  // xxsmall: '0.25rem',
  pagePadding: '30px',

  pagePaddingMobile: '20px',

  regular: '24px',

  small: '16px',

  smallMedium: '32px',

  xl: '192px',

  // 152px
  xlarge: '6rem',

  xsmall: '8px',

  // 280px
  xxlarge: '9.5rem',

  xxsmall: '4px',
} as const;

export type Spacing = keyof typeof spacing;
